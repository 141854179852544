import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import Layout from "../../layouts/default"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import HyperCard from "../../components/hyper-card"

const Workshops = () => {
  const data = useStaticQuery(graphql`
    query WorkshopsQuery {
      ogImage: file(relativePath: { eq: "og_images/workshops.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
      coverDGS: file(relativePath: { eq: "startseite/einfuehrung-dgs.png" }) {
        ...largeImage
      }
      coverDruckWorkshop: file(
        relativePath: { eq: "workshops/lauter-lieblingswoerter/cover.jpg" }
      ) {
        ...largeImage
      }
      coverHipHopWeiter: file(
        relativePath: { eq: "startseite/workshop-hiphop-weiter.png" }
      ) {
        ...largeImage
      }
      coverHipHop: file(
        relativePath: { eq: "startseite/workshop-hiphop.png" }
      ) {
        ...largeImage
      }
      coverFluss: file(relativePath: { eq: "workshop-fluss/cover.jpg" }) {
        ...largeImage
      }
      coverKalender: file(
        relativePath: { eq: "startseite/workshop-kalender.jpg" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Layout backdrop="workshops">
      <Seo
        title="Workshops"
        description="Hier stellen wir Gestaltungsideen zum kreativen Umgang mit der Sprache und anderen Materialien. Mit Tutorials, die Jung und Alt zum Schreiben, Erfinden, Basteln und Zeichnen anregen und garantiert die Langeweile vertreiben."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Workshops",
              link: "/workshops",
            },
          ]}
        />
        <PageTitle>Workshops</PageTitle>
        <Paragraph dropcap={true}>
          Hier stellen wir Gestaltungsideen zum kreativen Umgang mit der Sprache
          und anderen Materialien. Mit Tutorials, die Jung und Alt zum
          Schreiben, Erfinden, Basteln und Zeichnen anregen und garantiert die
          Langeweile vertreiben.
        </Paragraph>

        <HyperCard
          title="Lauter Lieblingswörter"
          headingElement="h2"
          image={data.coverDruckWorkshop}
          alt=""
          description={
            <>
              In diesem Workshop in Einfacher Sprache entstehen gedruckte
              Postkarten zu Hölderlins Lieblingswörtern. Mit der
              Linolschnitt-Technik kann dabei jeder ein eigenes Motiv für eine
              Druckvorlage entwerfen.
            </>
          }
          to="/workshops/lauter-lieblingswoerter"
          cta="Zum Workshop"
        />
        <HyperCard
          title="Einführung in die Gebärdensprache"
          headingElement="h2"
          image={data.coverDGS}
          alt="Die Gebärdensprach-Linguistin Dr. Daniela Happ trägt ein schwarzes Hemd und steht vor einem dunkelblauen Hintergrund."
          description={`In einem Video-Vortrag gewährt die taube Linguistin und Gebärdensprachdolmetscherin Dr. Daniela Happ einen Einblick in die Feinheiten der Deutschen Gebärdensprache. Dabei führt sie von der kleinsten bis zur größten Einheit der Gebärdensprache und lädt mit kleinen Aufgaben zum Ausprobieren und Mitmachen ein.`}
          to="/workshops/einfuehrung-dgs"
          cta="Zum Workshop"
        />
        <HyperCard
          title="Weitermachen, mit rappen – ein Tutorial von Spax"
          headingElement="h2"
          image={data.coverHipHopWeiter}
          alt="Der Rapper Spax trägt einen grauen Pullover und eine graue Baseball-Cap."
          description={`In der Fortsetzung unseres HipHop-Tutorials für Jugendliche und junge Erwachsene ab 15 Jahren führt der Rapper Spax in seine Arbeitsprozesse ein und verrät, mit welchen Kniffen welche Effekte erzielt werden können. Er erklärt, wie ein Rap-Text durch Betonungen, Takt, Tempo und Rhythmus in Sprechgesang verwandelt wird. Und vor allem: Wann und wie der Beat ins Spiel kommt.`}
          to="/workshops/weitermachen-mit-rappen"
          cta="Zum Workshop"
        />
        <HyperCard
          title="Wir sammeln Wörter und Bilder für das Jahr"
          headingElement="h2"
          alt="Ein Stapel illustrierter Kalender"
          image={data.coverKalender}
          description={`Ein Jahr steckt voller Bilder, Eindrücke und Ereignisse. Jede Jahreszeit kleidet die Welt in andere Farben und bringt neue Naturerscheinungen mit sich. In einem Kalender-Bastel-und Schreibworkshop stellen wir Ideen zur Gestaltung deines eigenen Kalenders vor.`}
          to="/workshops/kalender"
          cta="Zum Workshop"
        />
        <HyperCard
          title="Anfangen, zu rappen – ein Tutorial von Spax"
          headingElement="h2"
          image={data.coverHipHop}
          alt="Der Rapper Spax blickt in die Kamera und gestikuliert mit seinen Händen."
          description={`In diesem Video erklärt euch der Rapper Spax, wie ihr euren Gedanken und dem, was euch umtreibt, in Rap-Texten Ausdruck verleiht und wie ihr das Ganze in Sprechgesang verwandelt.`}
          to="/workshops/philosophie-hiphop"
          cta="Zum Workshop"
        />
        <HyperCard
          title="Wohin der Fluss mich treibt"
          headingElement="h2"
          alt="Selbstgebasteltes Leporello mit Flussverlauf"
          image={data.coverFluss}
          description={`Kann man auf Reisen gehen, ganz ohne in ein Flugzeug, in ein Auto oder in den Zug zu steigen? In drei Video-Tutorials für Kinder und Jugendliche von 10-14 Jahren zeigen wir, wie es geht und was das mit Hölderlin zu tun hat.`}
          to="/workshops/wohin-der-fluss-mich-treibt"
          cta="Zum Workshop"
        />
      </Stack>
    </Layout>
  )
}

export default Workshops
