/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"

import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Box from "./box"
import Button from "./button"
import Paragraph from "./paragraph"
import Grid from "./grid"
import Heading from "./heading"
import Icon from "./icon"
import Inline from "./inline"
import Stack from "./stack"
import Text from "./text"

const HyperCard = ({
  attribution,
  alt,
  image,
  title,
  to,
  color,
  headingElement,
  pretitle,
  description,
  cta,
}) => {
  const context = useThemeUI()
  const { theme } = context

  const srcImage = getImage(image)

  return (
    <Link
      to={to}
      sx={{
        display: "block",
        height: "100%",
        position: "relative",
        ".hyper-card-image, .hyper-card-box": {
          transition: `all ${theme.transitions["main"]}`,
        },
        ":hover .hyper-card-image, :focus .hyper-card-image": {
          transform: "scale(1.1)",
        },
        ":hover .hyper-card-button, :focus .hyper-card-button": {
          borderColor: "black",
        },
        ":hover .hyper-card-button *, :focus .hyper-card-button *": {
          color: "black",
        },
        ":hover .hyper-card-box, :focus .hyper-card-box": {
          bg: color,
        },
        ":hover .hyper-card-heading, :focus .hyper-card-heading": {
          color: "black",
        },
        ":hover .hyper-card-description, :focus .hyper-card-description": {
          color: "black",
        },
      }}
    >
      <Grid columns={[1, 1, 2]} space={0}>
        <Box
          sx={{
            height: [48, 48, "100%"],
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <GatsbyImage
              image={srcImage}
              alt={alt}
              className="hyper-card-image"
              sx={{
                width: "100%",
                height: "100%",
              }}
            />
            {attribution && (
              <Box position="absolute" bottom={0} left={0} right={0} p={3}>
                <Text
                  size="10px"
                  role="presentation"
                  color="white"
                  as="div"
                  sans
                >
                  <Inline space={1} alignX="right">
                    <span>Bild:</span>
                    <span>{attribution}</span>
                  </Inline>
                </Text>
              </Box>
            )}
          </Box>
        </Box>
        <Box className="hyper-card-box" bg="black" p={[6, 12]} height="100%">
          <Stack space={6}>
            {pretitle && (
              <Heading
                as="strong"
                color="whisper"
                className="hyper-card-heading"
              >
                {pretitle}
              </Heading>
            )}
            <Stack space={3}>
              <Heading
                as={headingElement}
                className="hyper-card-heading"
                lang="de"
                hyphens="auto"
                level={3}
                color="white"
              >
                {title}
              </Heading>
            </Stack>
            <Paragraph
              className="hyper-card-description"
              size={[2, 2, 3, 4]}
              color="white"
            >
              {description}
            </Paragraph>
            <Button
              className="hyper-card-button"
              color="white"
              borderColor={color}
              secondary
            >
              {cta}
            </Button>
          </Stack>
        </Box>
        <Box
          className="hyper-card-square"
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            zIndex: "10",
            bg: color,
            color: "black",
            p: 3,
            textAlign: "center",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon icon="arrow-right" />
        </Box>
      </Grid>
    </Link>
  )
}

HyperCard.propTypes = {
  alt: PropTypes.string,
  color: PropTypes.string.isRequired,
  headingElement: PropTypes.string,
}

HyperCard.defaultProps = {
  alt: "",
  color: "primary",
  headingElement: "h3",
}

export default HyperCard
